import React, { useState } from 'react';
import { DataFrameJSON, PanelProps, SelectableValue } from '@grafana/data';
import { Options } from '../module';
import { useAsync } from 'react-use';
import { config, getBackendSrv } from '@grafana/runtime';
import { Button, DatePickerWithInput, InlineField, MultiSelect, Spinner } from '@grafana/ui';
import { downloadBlob } from 'shared/utils';
import { Site } from 'shared/types';
import { BASE_URLS } from 'shared/constants';

const backendSrv = getBackendSrv();

export function MainPanel({ replaceVariables }: PanelProps<Options>) {
  // get all sites for select options, default to current site if we're on a site dashboard
  const [selectedSites, setSelectedSites] = useState<SelectableValue[]>([]);
  const { value: sites } = useAsync(async () => {
    const response = await backendSrv.get(`${BASE_URLS.API}/sites`);
    const allSites = (response?.data?.sites || []).map((site: Site) => ({
      ...site,
      startObj: new Date(site.start_date), // map and format dates
      endObj: new Date(site.end_date),
      label: site.name, // make it SelectableValue friendly
      value: site.id,
      description: `Active ${new Date(site.start_date).toLocaleDateString()} - ${new Date(
        site.end_date
      ).toLocaleDateString()}`,
    }));
    // .filter((site: Site & {endObj: Date}) => site.endObj >= new Date()) // filter to only active sites

    // if $siteBBox templates to something, we're not on the main dashboard
    if (replaceVariables('$siteBBox') !== '$siteBBox') {
      const currentSite = allSites.filter(
        (site: Site) => site.id === replaceVariables('${__dashboard.uid}').replace('-db', '')
      );
      setSelectedSites(currentSite);
    }

    return allSites;
  });

  // get start/end dates for the query. default to today - 30 days
  const today = new Date();
  const pastDate = new Date();
  pastDate.setDate(today.getDate() - 30);
  const [startDate, setStartDate] = useState<Date | string>(pastDate);
  const [endDate, setEndDate] = useState<Date | string>(today);

  // display download progress as a count of files downloaded over total
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  // make the queries per each site
  const queryData = async (site: SelectableValue): Promise<DataFrameJSON> => {
    // get the first datasource of this type, we should only have one.
    const datasource = config.bootData.settings.datasources['Timestream datasource'];
    const bbox = `${site.min_lon}, ${site.min_lat}, ${site.max_lon}, ${site.max_lat}`;
    const query = `
      SELECT
        bin(time, 1h) AS time_bin,
        device_id,
        measure_name,
        avg(measure_value::double) AS hourly_avg_value
      FROM
        $__database.sensors
      WHERE
        time BETWEEN from_milliseconds(${new Date(startDate).getTime()}) AND from_milliseconds(${new Date(
      endDate
    ).getTime()})
        AND
        -- siteBBox array is [min_lon, min_lat, max_lon, max_lat]
        cast(longitude as double) BETWEEN element_at(ARRAY[${bbox}], 1) AND element_at(ARRAY[${bbox}], 3)
        AND
        cast(latitude as double) BETWEEN element_at(ARRAY[${bbox}], 2) AND element_at(ARRAY[${bbox}], 4)
        AND
        measure_name IN ('tvoc', 'ir_c1', 'temperature', 'humidity', 'winDir', 'windSpeed', 'battery_gauge')
      GROUP BY
        bin(time, 1h),
        device_id,
        measure_name
      ORDER BY
        time_bin,
        device_id,
        measure_name
    `;
    const payload = {
      queries: [
        {
          datasource: { uid: datasource.uid },
          // @ts-ignore: jsonData.defaultDatabase seems to actually be in the obj...
          database: datasource.jsonData?.defaultDatabase,
          rawQuery: query,
          nextToken: undefined,
        },
      ],
    };
    let response = await backendSrv.post('/api/ds/query', payload);

    while (response.results.A.frames[0].schema.meta.custom.nextToken) {
      // if the response has a next token, try again.
      // without setting this token, your queries will lie to your fucking face and return 200 even though the results are empty.
      payload.queries[0].nextToken = response.results.A.frames[0].schema.meta.custom.nextToken;
      response = await backendSrv.post('/api/ds/query', payload);
    }
    return response.results.A.frames[0] || {schema: {fields: []}, data: {values: []}};
  };

  function dataFrameToCSV(dataFrame: DataFrameJSON) {
    const fields = dataFrame?.schema?.fields || [];
    const values = dataFrame?.data?.values || [];
  
    if (fields.length === 0 || values.length === 0) {
      return '';
    }
  
    const timeIndex = fields.findIndex(field => field.name === 'time_bin');
    const deviceIndex = fields.findIndex(field => field.name === 'device_id');
    const measureIndex = fields.findIndex(field => field.name === 'measure_name');
    const valueIndex = fields.findIndex(field => field.name === 'hourly_avg_value');
  
    const measureNames = Array.from(new Set(values[measureIndex] as string[]));
  
    // Create the header
    let csvContent = 'time,time_bin,device_id,' + measureNames.join(',') + '\n';
  
    // Create a map to store the rows
    const rowMap = new Map();
  
    const numRows = values[0].length;
    for (let i = 0; i < numRows; i++) {
      const time = values[timeIndex][i];
      const deviceId = values[deviceIndex][i];
      const measureName = values[measureIndex][i] as string;
      const avgValue = values[valueIndex][i];
  
      const key = `${time},${deviceId}`;
      if (!rowMap.has(key)) {
        rowMap.set(key, {
          time,
          time_bin: `"${new Date(time as number | string).toLocaleString()}"`,
          device_id: deviceId,
          measures: {}
        });
      }
  
      const row = rowMap.get(key);
      row.measures[measureName] = avgValue;
    }
  
    // Build the CSV content
    for (const [key, row] of rowMap) {
      let csvRow = `${row.time},${row.time_bin},${row.device_id}`;
      for (const measureName of measureNames) {
        csvRow += `,${row.measures[measureName] || ''}`;
      }
      csvContent += csvRow + '\n';
    }
  
    return csvContent;
  }
  

  // dispatcher to make queries and then download csvs
  const downloadData = async () => {
    // immediately provide feedback something is happening
    setDownloadInProgress(true);
    // download each site in turn, waiting patiently
    await Promise.all(
      selectedSites.map(async (site) => {
        // query to fetch the site's data
        const dataframe = await queryData(site);
        // format data
        const csvString = dataFrameToCSV(dataframe);
        // download as CSV
        downloadBlob(
          csvString,
          `${site.label} - ${new Date(startDate).toLocaleDateString()} to ${new Date(
            endDate
          ).toLocaleDateString()}.csv`,
          'text/csv;charset=utf-8;'
        );
      })
    );
    // report that downloading has completed
    setDownloadInProgress(false);
  };

  return (
    <div>
      <InlineField label="Start date" labelWidth={15} grow={true}>
        <DatePickerWithInput value={startDate} onChange={setStartDate} maxDate={new Date(endDate)} closeOnSelect={true} />
      </InlineField>
      <InlineField label="End date" labelWidth={15} grow={true}>
        <DatePickerWithInput value={endDate} onChange={setEndDate} minDate={new Date(startDate)} closeOnSelect={true} />
      </InlineField>
      <InlineField label="Sites" labelWidth={15} grow={true}>
        <MultiSelect
          placeholder="Select sites..."
          options={[{
            label: 'All active sites',
            value: 'allactive',
            description: `Selects sites active through today's date. Overwrites any previous selection.`
          }, ...(sites || [])]}
          value={selectedSites}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          // TODO: actually get grafana to fucking recognize this...
          toggleAllOptions={{
            enabled: true,
            optionsFilter: (v: SelectableValue) => v.endObj >= new Date(),
          }}
          onChange={(newSites) => {
            // if the 'allactive' option was added, overwrite any selection with only currently active sites
            if (newSites.some(site => site.value === 'allactive')) { 
              setSelectedSites(sites.filter((site: Site & {endObj: Date}) => site.endObj >= new Date()))
            } else { // otherwise just set whatever was selected
              setSelectedSites(newSites)
            }
          }}
          maxVisibleValues={5}
        />
      </InlineField>
      <div style={{margin: '8px'}}>
        Exported data will be averaged sensor measurements per hour. Your browser may prompt you to allow multiple
        downloads if more than one site has been selected.
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Button disabled={selectedSites.length === 0 || downloadInProgress} onClick={downloadData}>
          {!downloadInProgress ? (
            'Download data'
          ) : (
            <span style={{ display: 'flex' }}>
              <Spinner />
              &nbsp;&nbsp;Downloading...
            </span>
          )}
        </Button>
      </div>
    </div>
  );
}
